import React from 'react'
import PageEyebrow from 'components/PageEyebrow/PageEyebrow'
import LoadingMessage from 'components/LoadingMessage'
import CoverImage from 'components/CoverImage'
import CtaButton from 'components/CtaButton/CtaButton'
import { ContentLabel } from 'models'
import { getChipColorByContentBucketSlug } from 'utils/hooks'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { StructuredTextContainer } from 'components/Layout'
import { WORKSHEET_QUERY } from '../../gql/queries/contentBuckets'

const WorksheetContent = () => {
  const { slug } = useParams()
  const { data, loading } = useQuery(WORKSHEET_QUERY, { variables: { slug } })
  if (loading) return <LoadingMessage />

  const { worksheet } = data
  const label =
    ContentLabel[
      worksheet.contentBucket.slug as keyof typeof ContentLabel
    ]
  const bgColor = getChipColorByContentBucketSlug(
    worksheet.contentBucket.slug
  )

  return (
    <Box component='article' sx={{ width: '100%', padding: '40px 0' }}>
      <PageEyebrow
        label={label}
        backgroundColor={bgColor}
        time={worksheet.minutes}
        isVideo={false}
      />
      <Typography
        variant='h1'
        sx={{
          paddingBottom: 0
        }}
      >
        {worksheet.title}
      </Typography>
      <Grid container>
        <Grid sx={{ maxWidth: '800px' }} item xs={12}>
          <StructuredTextContainer
            roundedImage
            isInsight
            content={worksheet.content}
          />
        </Grid>
      </Grid>
      <CtaButton
        sx={{ margin: '40px 0' }}
        variant='contained'
        onClick={() => window.open(`${worksheet.pdf.url}`, '_blank')}
      >
        Download report PDF
      </CtaButton>
      <CoverImage
        isSlide={false}
        rounded
        responsiveImage={worksheet.image.responsiveImage}
      />
    </Box>
  )
}

export default WorksheetContent
