/* eslint-disable @typescript-eslint/no-unused-expressions */
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  Category,
  Colors,
  ContentBucket,
  ContentBucketType,
  TranscriptType
} from '../../models'

// Add plugins to dayjs
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

export const getChipColorByContentBucketSlug = (type?: string): string => {
  if (type) {
    const contentType: string =
      ContentBucketType[type as keyof typeof ContentBucketType]
    return Colors[contentType as keyof typeof Colors]
  }
  return Colors.DEFAULT
}

export const constructEyebrowTimeLabel = (
  timeInMinutes: number,
  onPage: boolean,
  isVideo?: boolean
): string => {
  // Convert to hours and minutes if greater than 60 minutes
  let minutes: number = timeInMinutes
  let roundedMinutes: number = timeInMinutes
  let hours: number = 0
  let roundedHours: number = 0
  const timeSuffix: string = isVideo ? 'view time' : 'read'
  let timeLabel = onPage
    ? `${roundedMinutes} minute ${timeSuffix}`
    : `${roundedMinutes} min`

  if (timeInMinutes > 59) {
    hours = timeInMinutes / 60
    roundedHours = Math.floor(hours)
    minutes = (hours - roundedHours) * 60
    roundedMinutes = Math.round(minutes)
    if (roundedMinutes > 0) {
      timeLabel = onPage
        ? `${roundedHours} hour ${roundedMinutes} minute ${timeSuffix}`
        : `${roundedHours} hr ${roundedMinutes} min`
    } else {
      timeLabel = onPage
        ? `${roundedHours} hour ${timeSuffix}`
        : `${roundedHours} hr`
    }
  }

  return timeLabel
}

export const constructEventTimeLabel = (date: string): string => {
  const eventIsoString: string = dayjs.utc(date).toISOString()
  const eventDate: Date = dayjs(eventIsoString).toDate()
  return dayjs
    .tz(eventDate, 'America/Los_Angeles')
    .format('MMM D [.] h:mm A [PST]')
}

export const getLinkUrl = (
  slug: string,
  contentBucket?: ContentBucket | null,
  category?: Category | null
): string => {
  if (contentBucket && contentBucket.slug === 'book') {
    return `/hiring-for-diversity/chapter/${slug}`
  }
  // If the content item belongs to a category
  if (category && contentBucket) {
    if (contentBucket && contentBucket.slug === 'toolkit') {
      return `/category/${category.slug}/${contentBucket.slug}/${slug}/slide/intro`
    }
    if (contentBucket && contentBucket.slug === 'community-insights') {
      return `/community-insights/${slug}`
    }
    if (contentBucket && contentBucket.slug === 'worksheet') {
      return `/worksheet/${slug}`
    }
    return `/category/${category.slug}/${contentBucket.slug}/${slug}`
  }
  if (contentBucket) {
    return `/${contentBucket.slug}/${slug}`
  }

  return `/${slug}`
}

export const constructTranscriptTimeLabel = (
  transcript: TranscriptType
): string => {
  let minuteMark: string = '00'
  let secondsMark: string = '00'
  if (transcript) {
    transcript.minuteMark && transcript.minuteMark < 10
      ? (minuteMark = `0${transcript.minuteMark}`)
      : (minuteMark = `${transcript.minuteMark}`)
    transcript.secondsMark && transcript.secondsMark < 10
      ? (secondsMark = `0${transcript.secondsMark}`)
      : (secondsMark = `${transcript.secondsMark}`)
  }
  return `${minuteMark}:${secondsMark}`
}
